<template>
    <div :style="style">
        <h2>{{title}}</h2>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'InfoCard',
    props: {
        color: String,
        title: String
    },
    computed: {
        style() {
            return {
                background: this.color,
            }
        }
    },
}
</script>

<style scoped>
    div {
        padding: 10px;
        margin: 2px;
    }

    div:first-child {
        border-radius: 10px 10px 0 0;
    }

    div:last-child {
        border-radius: 0 0 10px 10px;
    }
        
</style>