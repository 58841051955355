<template>
    <div>
        <p>Please send <img class="paypal-icon" src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal" /> contributions to ansel.landini@gmail.com </p>
        <div class="pay-btn paypal-btn" onclick="window.open('https://www.paypal.com/myaccount/transfer/homepage/pay','_blank')"><img width="100" src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal" /> </div>
        <div class="pay-btn venmo-btn" onclick="window.open('https://account.venmo.com/search?q=Ansel-Landini&tab=0', '_blank')"><img width="100" src="@/assets/venmo_logo_white.png" alt="Venmo" /></div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pay-btn {
        cursor: pointer;
        width: 90%;
        max-width: 500px;
        border-radius: 10px 0 10px 0;
        margin-bottom: 16px;
        height: 50px;
        box-shadow: 1.5px 1.5px 3px 0 black;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .paypal-btn {
        background-color: #ffc439;
        transition: background-color 0.25s ease-in-out;
    }

    .paypal-btn:hover {
        background-color: #e6ab20;
    }

    .paypal-icon {
        padding-left: 5px; padding-right: 5px;
        width: 60px;
    }

    .venmo-btn {
        background-color: #3d95ce;
        transition: background-color 0.25s ease-in-out;
    }

    .venmo-btn:hover {
        background-color: #57AFE8;
    }
</style>