<template >
  <div class="registry flower scrollable">
    <div class="page-content">
      <h1>Registry</h1>
      <p>For our registry, we're asking that guests donate to our honeymoon fund. <br>
        Please add a note to your donation with your name.<br>
        Below are links to contribute to our registry fund via <img class="paypal-icon" src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal" /> or <img class="venmo-icon" src="@/assets/venmo-ar21.svg" alt="Venmo" /><br>
        </p>
      <Payments />
    </div>
  </div>
</template>

<script>
import { useState } from '@/store.js';
import Payments from '@/components/Registry/Payments.vue'
export default {
  title: 'Registry',
  setup() {
    return{state: useState()}
  },
  created: function() {
    this.state.isHome = false;
  },
  components: {
    Payments
  }
}
</script>

<style scoped>

  .paypal-icon {
    padding-left: 5px; padding-right: 5px;
    width: 60px;
  }

  .venmo-icon {
    width: 60px;
  }

</style>