<template>
    <div class="vows-container">
    <div class="vows">
        Stephanie,<br>
        When we first met, I was at a low point in my life. <br>
        But with a warm smile, and a semi-suspicious amount of interest 
        in GameCube memory cards, you lifted me up. <br><br>

        You’re unlike anyone I’ve ever met, immediately down for my crazy ideas 
        like “Hey what if we DROVE to the east coast together in your newly acquired Nissan Sentra??”<br><br>

        And someone I can laugh with hysterically, burned out, 13 hours into that 17 
        hour drive back from New York, when you turned to me and said: <br>
        “Hey this might be fake news, but apparently a baby with all black eyes said 
        ‘this body is limiting’ to its mother”. <br><br>

        We still laugh daily, and although it may be a cliché,
        I’m so glad to have found someone to smile with.<br><br>

        Someone who kisses me every morning because I read once that men who get a kiss
        goodbye are less likely to be in an accident on their way to work. <br><br>

        And who has this intense empathy, which although causing you to cry at every 
        emotional moment in a movie, has pushed me, an exasperatingly logical person, 
        to challenge my personal escapisms, take better care of myself mentally, 
        and to do my best to take care of others in need. <br><br>

        You’ve made me so happy during these years we’ve spent together, that those 
        melancholy days are a distant memory. <br>

        So to show my love and appreciation, I want you to know this:<br><br>

        I will always bring you medicine when your head hurts, 
        even though you hate taking medicine.<br><br>

        I will always strive to find compromises, and to 
        solve our problems in ways that make us both feel heard. <br><br>

        I will always ask you about the drama in your TV show 
        even though I’ve only seen 15 minutes of it, 
        and to subsequently force you to fill me in on the entire plot 
        when I jump in on episode 36. <br><br>

        To try my best to understand, even though sometimes I don’t. <br><br>
        And to share our better insights with our future family. <br><br>

        I cannot wait to grow old with you, like the trees my dad and I argue about. <br>
        The ones with compound trunks that look like they were at one point separate trees, 
        but grew together from decades of strengthening each other. <br><br>

        Stephanie Jensen, you are my world, and I love you sooooo~~~ much. <br>
        I am so excited to see what our future holds.
    </div>
    </div>
</template>

<style scoped>
    .letter-container {
        display: flex;
        justify-content: center;
    }
    .vows {
        font-family: 'Dancing Script', cursive;
        font-size: 17px;
        text-align: center;
    }
</style>
