<template>
    <div>
        <table>
            <tr>
                <td style="text-align:center">
                    Keep your ties at home, we're keeping things 
                    <a target="_" :href=url>dressy casual</a>.
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td>
                    Cocktail Hour
                </td>
                <td>
                    5pm to 6pm
                </td>
            </tr>
            <tr>
                <td>
                    Dinner
                </td>
                <td>
                    6pm to 7pm
                </td>
            </tr>
            <tr>
                <td>
                    Social
                </td>
                <td>
                    7pm to 10:30pm
                </td>
            </tr>
            <tr></tr>
            <tr>
                <td>Open Bar</td>
                <td>5pm to 10:00pm</td>
            </tr>
            <tr>
                <td colspan="2" style="text-align:center">
                    <i>Please drink responsibly</i>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td style="text-align:center">
                    After the reception concludes, meet us at <a target="_" href="https://www.ricochetdsm.com/">Ricochet</a> 
                    for drinks and games.
                </td>
            </tr>
        </table>
    </div>
    <p></p>
</template>

<script>
export default {
    name: 'ScheduleTable',
    data() {
        return {
            url: "https://www.masterclass.com/articles/how-to-put-together-a-dressy-casual-outfit#:~:text=Tan%20" +
                "France's%20MasterClass-,What%20Is%20Dressy%20Casual%20Attire3F,dark%20jeans%2C%20and%20clean%20shoes."
        }
    },
}
</script>

<style scoped>
    table {
        margin: auto;
        font-weight: bold;
    }
    table td {
        text-align: left;
        padding: 0px 10px;
    }
</style>