<template>
  <div class="ceremony flower scrollable">
    <div class="page-content">
        <h1>Ceremony Transcript</h1>
        <h3>
          Below are our wedding vows. <br>
          Thanks again to Christopher Jensen for being a wonderful officiant, <br> 
          and to everyone who shared in this wondeful day. <br>
          We can't wait to see you all in 2022!!
        </h3>
       <div class="divider"></div>
       <br>
       <Transcript />
       
    </div>
  </div>
</template>

<script>
import { useState } from '@/store.js';
import Transcript from '@/components/Ceremony/Transcript';
export default {
    title: 'Ceremony',    
    setup() {
        return{state: useState()}
    },
    created: function() {
        this.state.isHome = false;
    },
    components: {
        Transcript
    }
}
</script>

<style scoped>
    .divider {
        border-top: 1.5px solid rgba(128, 128, 128, .6);
    }
</style>