<template>
    <br>
    <div class="separator">
        <img v-if="fancy" height="50" src="../../assets/fancy-divider.png"/>
        <p v-else>{{title || "·"}}</p>

    </div>
    <br>
</template>

<script>
export default {
    name: "Separator",
    props: {
        fancy: Boolean,
        title: String
    }
}
</script>

<style scoped>
    p {
        padding: 0;
        margin: 0;
    }
    .separator {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .separator::before,
    .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }

    .separator:not(:empty)::before {
        margin-right: .25em;    
    }

    .separator:not(:empty)::after {
        margin-left: .25em;
    }
</style>