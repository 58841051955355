<template>
    <div>
    <InfoCard :color="scheduleColor" title="Event Info">
        <ScheduleTable />
    </InfoCard>
     <InfoCard :color="locationColor" title="Location">
        <div class="marriott-map"></div>
        <p>
            Des Moines Marriott Downtown<br>
            3rd Floor, Hall of Cities<br />
            700 Grand Ave<br>
            Des Moines, IA 50309
        </p>
    </InfoCard>
    <InfoCard :color="stayColor" title="Stay">
        <p>The promotional rate for stay at the Marriott has expired. <br>
        However, we may still be able to get you a booking at the promotional rate if you missed out. <br>
        Please reach out if you need a booking.</p>

        <div class="book-stay" v-if="false" onclick="window.open('https://www.marriott.com/events/start.mi?id=1634754613464&key=GRP', '_blank')">Book Now</div>
    </InfoCard>
    </div>
</template>

<script>
import InfoCard from '@/components/Info/InfoCard.vue'
import ScheduleTable from '@/components/Info/ScheduleTable.vue'
export default {
    name: 'InfoCards',
    components: {
        InfoCard,
        ScheduleTable
    },
    data() {
        return {
            locationColor: "rgba(0, 168, 160, 0.4)",
            stayColor: "repeating-linear-gradient(45deg, rgb(196,2,15,.4), rgb(196,2,15,.4) 10px, rgb(45, 45, 45, 0.4) 10px, rgb(45, 45, 45, 0.4) 20px)",
            scheduleColor: "rgb(45, 45, 45, 0.4)"
        }
  }
}
</script>

<style scoped>
    .marriott-map {
        margin: 0 auto;
        max-width: 450px;
        height: 450px;
        background-image: url('../../assets/des-moines-marriott-downtown.png');
        background-position: center;
        background-size: 450px 450px;
    }

    .book-stay {
        cursor: pointer;
        margin: 0 auto;
        width: 200px;
        border-radius: 10px;
        margin-bottom: 16px;
        height: 50px;
        box-shadow: 1.5px 1.5px 3px 0 black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
        background-color: #B41F3A;
        color: white;
    }

    .book-stay:hover {
        background-color: #cd2342;
    }

    p {
        font-weight: bold;
    }
</style>