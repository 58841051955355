<template>
    <div class="photo-gallery" :style="style">
        <div :key="img" v-for="img in files" >
            <Photo :src="img" />
        </div>
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import Photo from "./Photo";
const images = require.context("@/assets/Wedding_Photos", true, /\.jpg$/);
export default {
    components: {
        Photo
    },
    data() {
        return {
            files: []
        }
    },
    computed: {
        style() {
            return {
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(auto-fill, minmax(20rem, 1fr))' : 'repeat(auto-fill, minmax(27rem, 1fr))',
                gridGap: '1rem',
                height: 'auto',
                maxWidth: '100%',
                marginTop: '20px',
                padding: '5px',
                marginRight: '5px', 
                marginLeft: '5px',
            }
        }
    },
    methods: {
        getUrls() {
            images.keys().forEach(fileName => {
                // Get component config
                this.files.push(images(fileName))
            });
        },
        getImg(name) {
            return `@/assets/Wedding_Photos/${images(name)}`
        }
    },
    mounted() {
        this.getUrls();
    }
   
       
}
</script>

<style scoped>
    /* .photo-gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-gap: 1rem;
        height: auto;
        max-width: 100%;
        margin-top: 20px;
        padding: 5px;
        margin-right: 5px; margin-left: 5px;
    } */

</style>