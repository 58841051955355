<template>
<div class="transcript-container">
<div class="transcript" :style="style">
    <Separator id="introduction" title="Introduction" />
    <p>I’d like to first welcome everyone to Ansel & Stephanie’s wedding. 
    Thank you all for being here. 
    And I’d also like to welcome and thank everyone at home watching as well. <br><br>
    
    I know this crowd is a lot smaller than the couple may have imagined their dream wedding to be,
    But the couple wanted you all to know they appreciate everyone coming
    despite the uncertainties that the pandemic brings. 
    And are so happy they could share this moment with everyone.<br><br>

    We of course know we're here today to celebrate their love for each other. 
    But I'd like to start by reliving their love story from the start until now. <br><br>

    They first met each other at Hy-Vee while Stephanie worked in Floral and Ansel in Catering. 
    The couple recalls their first date was in August of 2016, where they went to a bonfire 
    and watched Fantasia. They became official on January 13th, 2017, after Ansel told Stephanie to 
    turn someone down who wanted to have drinks with her because 'she had a boyfriend now'. <br><br>

    He later moved to Des Moines with her in April 2018, a year after she had already been 
    living there, where they got their dog Apollo. 
    They bought their house together in March of 2019,
    where they then got their second dog Antheia. <br><br>

    I know we all saw where things were going around this time, and sure enough, Ansel proposed 
    to Stephanie on June 19, 2020 -- with a beautiful ring with a ranunculus entwined in it;
    Stephanie's favorite flower. 
    Stephanie was playing hide and seek with Apollo and when Apollo found her he had a ring 
    in a bag around his neck that said "I found you!" 
    Inside it said "And now I'm never letting you go" 
    Along with the question that led us to where we are today: <br>
    <i>"Will you marry me?"</i><br>
    You two have had many great milestones and this ceremony today
    will only be the start of many more to come. <br><br>

    I hope you keep each other peace during sickness - be it mental or physical. <br><br>
    I hope you keep each other peace during disagreements,
    by understanding one another; listening to one another. <br><br>
    I hope you two celebrate each other in the good times, when in good health. <br><br> 
    I hope you celebrate each other when achieving personal milestones. <br><br>
    I hope you celebrate and lift each other up one day when children come into the picture. <br><br> 
    I hope whatever challenges or achievements you have in the future,
    that you allow them to fuel your love instead of dimming it. <br><br>

    Whatever the case may be, today you two start a new chapter together
    and we all wish you many years of celebration and peace together. 
    And I know everyone here is so happy to see how your love for each other and lives together will grow.<br><br>
    I asked our dad (Steve) and Ansel's parents to prepare a letter for Stephanie and Ansel respectively:
    </p>
    <Separator id="letters" title="Letters" />
    <AnselLetter />
    <Separator fancy />
    <StephLetter />
    <Separator />
    <p>Thank you guys for these letters -- your words were truly special.
    I'm going to stop talking for once and let the couple speak. 
    Stephanie and Ansel chose to write their own vows to one another.
    Ansel if you would please share yours first. <br></p>

    <Separator id="vows" title="Vows" />
    <AnselVows />
    <Separator fancy />
    <br>
    <StephVows />
    <br>
    <Separator />

    <p>That was beautiful. 
    We'll now proceed to the exchanging of the rings. <br>
    Thank you, Austin, for holding the rings for the couple. <br><br>
    Ansel - take this ring and place it on Stephanie's finger. 
    Repeat after me: <br>
    "I, Ansel Ian Landini, take thee Stephanie <br>
    To have, to hold, to honor and respect."<br><br>
    Stephanie, please take this ring and place it on Ansel's finger.
    Repeat after me: <br>
    "I, Stephanie Renee Jensen, take thee Ansel <br>
    To have, to hold, to honor and respect."<br><br>
    Do you, Ansel, take Stephanie to be your lawfully wedded wife? <br>
    "I do" <br>
    Do you, Stephanie, take Ansel to be your lawfully wedded husband? <br>
    "I do" <br>
    Then it is my ultimate pleasure to now pronounce you husband and wife. <br>
    You may kiss the bride.</p>
</div>
</div>
    
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import AnselLetter from './AnselLetter';
import StephLetter from './StephLetter'
import AnselVows from './AnselVows';
import StephVows from './StephVows';
import Separator from './Separator';
export default {
    components: {
        AnselLetter,
        StephLetter,
        AnselVows,
        StephVows,
        Separator
    },
    computed: {
        isMobile() {
            return isMobile;
        },
        style() {
            return {
                width: isMobile ? '100%' : '50%',
                paddingLeft: '15px',
                paddingRight: '15px',
                textAlign: 'center',
            }
        }
    }

}
</script>


<style scoped>
    .divider {
        margin: 10px 0;
        border-top: 1.5px solid rgba(128, 128, 128);
    }
    .transcript-container {
        display: flex;
        justify-content: center;
    }
</style>