<template>
    <div class="vows-container">
    <div class="vows">
        First and foremost -- I love you. <br>
        Our love story starts with a cinnamon roll -- which lead to us finding out that
        we had the same obscure, favorite movie. <br>
        And since then I knew you were special and were going to play a big part in my life. <br>
        My heart has always been yours from the very beginning.<br><br>

        You are a gem. So much so that I bought my first ever hokey crystal, the citrine,
        because I read they were good for love and interpersonal relationships,
        and I just wanted to do whatever I could to protect what was growing between us, 
        however possible, and admittedly I did it a little overzealously at times :) <br><br>

        You know my favorite poem and line: <br>
        Pablo Neruda's Poem 14 Every Day You Play -- <br>
        "Quiero hacer contigo lo que la primavera hace con los cerezos." <br>
        (I want to do with you what Spring does to the cherry trees.) <br>
        You are my best friend, my heart, my soul. And I will always try to make you bloom and keep you there.<br>
        I will always be there to light the path for you wherever life leads, to always give you light in the darkness. <br>
        To get you up and keep you elevated, for you deserve always to soar and feel the warmth of happiness in your being.<br><br>

        Because you are always kind to me even when I don't deserve it,
        and you consistently go above and beyond for me. <br>
        From taking the dogs out because I don't have pants on, 
        to going out of your way to bring me a drink while I'm doing crafts in my office,
        to covering for me in embarrassing situations, you always take care of me. <br>
        And I promise to return the favor every single day, to take care of you and to cherish you
        and everything you do for me and our little family. <br>
        I want to continue sharing laughter and life and love with you.<br><br>

        When I gave you the cinnamon roll, you pondered something like, "why is everything that's good so bad for you?" <br>
        You couldn't be more wrong, my love, because you are Good, and I cannot imagine anything greater than having had you 
        come in to my life... Except maybe a third dog ;) <br><br>

        Every time I see the crinkles beside your eyes when you smile, 
        to when you gently kiss me on the forehead, to when your chest transforms into a galaxy and we become each other, 
        I am especially reminded of how lucky I am to have you in my life, of how wonderful this life is, 
        and of how absolutely sure I am that you are the best there ever is.<br><br>

        Anyway, I don't have a cinnamon roll today, but I do have this. <br>
        [Stephanie hands Ansel an almond]<br>
        I will always give you my almonds. <br>
        I love you, for every.
    </div>
    </div>
</template>

<style scoped>
    .letter-container {
        display: flex;
        justify-content: center;
    }
    .vows {
        font-family: 'Dancing Script', cursive;
        font-size: 17px;
        text-align: center;
    }
</style>
