<template>
  <div class="photos flower scrollable">
    <div class="page-content">
      <h1>Photos</h1>
      <p>Check here to see photos from our wedding ceremony!</p>
      <div class="photo-credits">
        <div class="photo-credit">
          Joshua Benda Photography:&nbsp;&nbsp;&nbsp;  
          <a href="https://instagram.com/joshua_bendaphotography" title="Instagram" ><img src="../assets/instagram_icon.png" alt="web link for Joshua" style="width:32px;height:32px;"></a>
          <a href="https://bendaboy1.wixsite.com/jhbenda/projects" title="Website"><img src="../assets/web_icon.png"  alt="web link for Joshua" style="width:32px;height:32px;margin-left: 5px;"></a>
        </div>
        <div class="photo-credit">
          Bella Rae Photography:&nbsp;&nbsp;&nbsp;
          <a href="https://instagram.com/bella.rae.photography" title="Instagram" ><img src="../assets/instagram_icon.png" alt="web link for Joshua" style="width:32px;height:32px;"></a>
        </div>
      </div>
      <PhotoGallery />
    </div>
  </div>
</template>

<script>
import { useState } from '@/store.js';
import PhotoGallery from '@/components/Photos/PhotoGallery';
export default {
  title: 'Photos',
  components: {
    PhotoGallery
  },
  setup() {
        return{state: useState()}
  },
  created: function() {
      this.state.isHome = false;
  },
}
</script>

<style scoped>
  .photo-credit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .photo-credits {
    display: flex;
    flex-direction: column;
  }

  img {
    line-height: 16px;
    vertical-align: middle;
  }
</style>