<template>
    <div class="letter-container">
    <div class="letter">
        To Stephanie, <br>
        First, a word to Nick and Chris, my love for you two is not diminished, 
        it is equally as strong, yet Steph has always been a little special. <br>
        I knew this day was coming when I saw her come out into the world. <br>
        I knew that it would be a very emotional roller coaster of a day for me. <br>
        Sadness for myself, as it would be affirmation that my little girl was a grown woman 
        and my job of helping her along the way was done. <br>
        Along with the happiness for her that she had found a partner to help navigate this crazy world. <br><br>
        Her mom wanted to name her Renee, which is a pretty name, but I took one look 
        and said "Hi Stephanie". Mom agreed and that's how we got our lovely Stephanie Renee. <br><br>
        As a baby I think she was worried about missing something or being left behind, 
        as she learned to crawl, run, and then walk. <br>
        Until age 9-10 she ran everywhere. Didn't matter how far or close. <br>
        I would be telling Chris to hurry as he liked to dawdle and study everything he saw 
        and Stephanie to slow down 'cause she wanted to see everything. <br>
        Nick just shook his head like 'what are they doing?'. Overall they were really good to shop with. <br>
        Hell in private, but angels in public. <br>
        Never had to spank Stephy. If she did something naughty, I just had to give her a look and say 
        "I'm very disappointed in you!". She would start crying and I would have to pretend I had 
        something else to do and step away before I started crying too. <br><br>
        We had a lot of fun and memorable times. One that stands out was Worlds of Fun, I think she was 10. <br>
        I talked her into riding The Mamba with me. They have cameras alongside the track for riders 
        to purchase their pic. Stephy's face was a look of pure terror. <br>
        I did feel bad in that moment. <br><br>
        One of our funnier memories, (sorry Stephy). <br> 
        We were looking on the calendar to see when Nick's b'day would be on a Friday the 13th. <br>
        Steph asked if we could look and see when her's would be. <br>
        "Steph", I said "yours will never be" <br>
        "Why?" she asked, hurt by my reply. <br>
        "Honey, your b'day is on the 6th"<br><br>
        One that I will never forget ever, I don't remember what was being discussed. <br>
        But Stephy asked if after school was done, if she got a job, could she live with me forever. <br>
        I said "well of course you can." A part of me wished that would come true. <br>
        But the bigger part of me knew this was selfish. <br>
        Stephy had a big world to see and lots of things to accomplish.<br><br>
        I swear, I only blinked my eyes twice and we went from that delivery room in Sioux Falls that 
        November day in 1992 to this day here! <br>
        I was 27 and people my age now told me this would happen 
        and I understood the words they were telling me, but I didn't fully appreciate the meaning 
        of what they were telling me. <br>
        Steph, I am so proud of you, the likes of which there are no words to explain.<br><br>
        Ansel, I ask you one thing and one thing only. Please always try to love and 
        respect her as you would want her to love and respect you. <br><br>
        I love you both and will be there for you for the rest of my life.
    </div>
    </div>
</template>

<style scoped>
    .letter-container {
        display: flex;
        justify-content: center;
    }
    .letter {
        font-family: 'Courier New', monospace;
        font-style: italic;
        text-align: justify;
    }
</style>