<template>
  <div class="info flower scrollable">
    <div class="page-content">
      <h1>Reception Information</h1>
      <InfoCards />
    </div>
  </div>
</template>

<script>
import { useState } from '@/store.js';
import InfoCards from '@/components/Info/InfoCards.vue'
export default {
  title: 'Info',
  setup() {
    return{state: useState()}
  },
  created: function() {
    this.state.isHome = false;
  },
  components: {
    InfoCards
  },
}
</script>

<style scoped>
  h1 {
    margin-bottom: 20px;
  }
  

</style>