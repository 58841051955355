<template>
    <div class="photo">
        <img :src="src">
    </div>
</template>

<script>
export default {
    props: {
        src: String
    }
}
</script>

<style scoped>
    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

</style>