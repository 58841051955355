<template>
    <div class="letter-container">
    <div class="letter">
        To Ansel,<br>
        We closed our eyes but a moment, and when they opened you stood where a boy used to be.<br>
        You have always had a strong sense of self and your capabilities.<br>
        As a toddler you held your own around your brother Austin's friends.<br>
        You strove to hone your piano skills, playing Für Elise for dad and the first movement
        of Beethoven's Sonata No. 8 in C Minor, Op. 13 for your senior recital. <br>
        You wrote enthusiastically in high school for the West Side Story. <br>
        You received degrees in Computer Science and Math at Iowa,
        and then persisted until you recently found a job suited to your strengths. <br><br>
        Your personal sense of individuality has manifested itself over the years. <br>
        We smile as we look back at haircuts, clothing, artwork, and poems. <br>
        But individuality is an asset and you have shown us repeatedly how it has benefited 
        and molded you into the incredible person you are today. <br>
        Challenges have never daunted you. You approached them with an acceptance and 
        tenacity that has followed you throughout your life. <br>
        Never giving up, always looking for the positives, confident in your abilities 
        even in some scary moments. <br><br>
        We have long admired your strong sense of purpose.<br>
        That comes with responsibilities as well, but you've taken them on with determination 
        and excitement. You have opened your arms wide and have purchased a home 
        and adopted two wonderful dogs. <br>
        You have looked outward and embraced the world and those in it. <br>
        You are a long-term friend to many and maintain a close, loving relationship 
        with your parents and brother. And on top of it all you came to know Stephanie. <br><br>
        We can no longer carry you in our arms, but we will ALWAYS carry you in our hearts. <br>
        You have given us so many reasons to be joyously proud of who you have become, 
        but the proudest moment yet is seeing you have realized that: <br>
        "Happy is the person who finds a true friend; and far happier still is a person 
        for whom that true friend becomes a wife."<br>
        You have found her, Stephanie, and we can feel the love you both share in your hearts. <br>
        May today commemorate enduring love, exciting adventures, and joyful memories to last a lifetime.<br>
        L'chaim!
    </div>
    </div>
</template>

<style scoped>
    .letter-container {
        display: flex;
        justify-content: center;
    }
    .letter {
        font-family: 'Courier New', monospace;
        font-style: italic;
        text-align: justify;
    }
</style>